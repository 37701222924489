import React from "react"
import { StaticImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import TrustedBy from "../components/hireVa/trusted"
import SaveTime from "../components/hireVa/savetime"
import GoodChoice from '../components/hireVa/goodchoice'
import PostingRole from '../components/hireVa/postingrole'
import Assistant from '../components/hireVa/assistant'
import Booking from '../components/hireVa/booking'
import Testimonials from "../components/testimonials"
import Faqs from "../components/faqs.js"
import GetInTouch from  '../components/hireVa/getintouch'

import { useStaticQuery, graphql, Link } from "gatsby"





const Virtual_assistants = () => {
 

  /*
  const handleSubmit = (e) => {
    let myForm = document.getElementById('appointment');
let formData = new FormData(myForm);

const first_name = formData.get('name');
//console.log('FormData', formData);

      fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams( formData).toString()
    })
      .then(() =>{

        document.getElementById("response_msg_vr").innerHTML = 
        `<div class="alert alert-success" role="alert">
        Thank you <h2>${first_name}</h2> for the information, our Account Manager will be in touch with you as per selected timings..
        </div>`;
       // alert("Success!");
        document.getElementById("appointment").reset();

      })
      .catch(error => alert(error));

    e.preventDefault();

      
  }
*/
  const banner_title  = ['Fully Managed', 3000, 'Fully Trained ', 2000];
  const banner_button = 'Hire Vetted Virtual Assistant';

return (

    <Layout>
        <Seo title="Find the best freelance Virtual Assistants as per Demand  " />
        <Banner title = {banner_title} sticky_first = "Hire" sticky_second  = "Vetted Virtual Assistants!" button = {banner_button} />



<section className = "inro" >
<div className = "container">
  <p>You stay on land we go under the water.</p>
</div>
</section>


<section className = "ecommerce_services va_hire">



    <div className = "service" key = "1">
      <Link to = {`/creative-service/`}>

      <StaticImage
                            src="../images/c.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />
 
      <div><h2>creative</h2>
      <h4>virtual assistant</h4></div>
      </Link>
    </div>

    <div className = "service" key = "1">
      <Link to = {`/ecommerce-service/`}>

      <StaticImage
                            src="../images/e.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />
 
 <div> <h2>e-commerce</h2>
      <h4>virtual assistant</h4></div>
      </Link>
    </div>

    <div className = "service" key = "1">
      <Link to = {`/hr-service/`}>

      <StaticImage
                            src="../images/h.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />
 
 <div><h2>recruiter</h2>
      <h4>virtual assistant</h4></div>
      </Link>
    </div>

    <div className = "service" key = "1">
      <Link to = {`/development-service/`}>

      <StaticImage
                            src="../images/g.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />
 
 <div> <h2>generalist</h2>
      <h4>virtual assistant</h4></div>
      </Link>
    </div>

    <div className = "service" key = "1">
      <Link to = {`/marketing-service/`}>

      <StaticImage
                            src="../images/m.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />
 
 <div>  <h2>marketing</h2>
      <h4>virtual assistant</h4></div>
      </Link>
    </div>

    <div className = "service" key = "1">
      <Link to = {`/pm-service/`}>

      <StaticImage
                            src="../images/p.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />
 
 <div> <h2>project manager</h2>
      <h4>virtual assistant</h4></div>
      </Link>
    </div>



</section>

 { /*  <div className = "vr_banner">
     
        <StaticImage
                            src="../images/vr_banner.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

        <div className = "details container">
        <div className = "details_wrap">

            <h1>worry less, do more</h1>
            <p>
            Delegate tasks that eat up too much of your time. Do it in a stress-free way.
            U.S based virtual assistants. As low as $10 / hour.
            </p>
            <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">hire virtual assistants</Link>
        </div>
        </div>


        </div> */ }

  
<TrustedBy/>

<SaveTime/>




{ /*
<section className = "expertice">

        <div className="container">

            <div className = "exp_row">

                    <div className="exp_cont">
                        <h1>you name it,</h1>
                        <h1>we have it</h1>
                        <p>Get expert assistants for any idea of your business.</p>

                        <ul>
                            <li><Link to = "/creative-service">creative</Link></li>
                            <li><Link to = "/ecommerce-service">E-commerce</Link></li>
                            <li><Link to = "/hr-service">human resources</Link></li>
                            <li><Link to = "/development-service">development</Link></li>
                            <li><Link to = "/marketing-service">marketing</Link></li>
                            <li><Link to = "/pm-service">project manager</Link></li>
                        </ul>

                    </div>

                    <div className="exp_img">
<video  width="456" height="362"  muted loop ontrols="false" autoplay="autoplay">
<source src = {EstoreVideo} type="video/mp4" />


</video>

                  

                    </div>

            </div>


        </div>

    
</section>
*/
}


<GoodChoice/>




<section className = "trained">

    <div className="container">

      <h1>All of our Virtual Assistants are trained in our in house Boot camps </h1>

        <ul>

        <li>
        <StaticImage
            src="../images/adobe.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Trained for Design</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/Mail-chemp.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Certified Mail Chimp News Letter</h4>

        </li>



        <li>
        <StaticImage
            src="../images/clickup.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Trained for Project Management </h4>

        </li>



        <li>
        <StaticImage
            src="../images/shopify-ico.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Trained Shopify Experts</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Trained-Go.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Trained Go high Level</h4>

        </li>



        <li>
        <StaticImage
            src="../images/google-microsoft.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Gsuite and Microsoft Email</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Pod-cost.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Basic Pod cost skills</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Social-Media.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Social Media research and post</h4>

        </li>




        <li>
        <StaticImage
            src="../images/copy-writing.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Basic copy writing and blog post skills</h4>

        </li>




        <li>
        <StaticImage
            src="../images/SOP.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Trained and SOP</h4>

        </li>




        <li>
        <StaticImage
            src="../images/youtube.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>youtube Video edit and post</h4>

        </li>




        <li>
        <StaticImage
            src="../images/Amazon-VA.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Trained Amazon VA</h4>

        </li>




        </ul>

    </div>


</section>


{/*
<section className ="will_get">


    <div className="container">

        <h1>what you will get</h1>

        <ul>
            <li><h4>get more tasks done at a  lower cost</h4></li>
            <li><h4>no overhead or employee benefits cost</h4></li>
            <li><h4>flat rate per hour for any types of skill set</h4></li>
            <li><h4>more time saving  and economy so you can focus on strategic things</h4></li>
            <li><h4>already sorted and screened top U.S telent</h4></li>
            <li><h4>24 hours of backup so you can scale your business</h4></li>
        </ul>


    </div>
</section>



<section className = "pricing">


        <div className = "container">

            <div className="pricing_row">

                <div className="price_box">

                    <h1>starter</h1>

                    <div className="prices_part">
                    <span  className = "dollar"><i class="fa fa-usd"></i></span>
                    <span  className = "price">800</span>
                    <span  className = "month">/month</span>
                    <h4>60 hour</h4>
                    </div>

                    <h4>What's included:</h4>
                        <ul>
                            <li><i class="fa fa-check"></i><h6>Enjoy Assistance with a desired number of hours starting from 10 Hours to 60 Hours</h6> </li>
                            <li><i class="fa fa-check"></i><h6>Get a Point Of Contact for all your work </h6></li>
                            <li><i class="fa fa-check"></i><h6>Start, Stop, Change your Plan Any Time  </h6></li>
                            <li><i class="fa fa-check"></i><h6>Buy Additional Hours If Needed </h6></li>
                            <li><i class="fa fa-check"></i><h6>Furnished With Multi-Layered Quality Control System</h6></li>


                        </ul>

                    <Link to = "/contact-us">try starter</Link>

                </div>

                <div className="price_box">

<h1>popular</h1>

<div className="prices_part">
<span  className = "dollar"><i class="fa fa-usd"></i></span>
<span  className = "price">1200</span>
<span  className = "month">/month</span>
<h4>80 hours</h4>
</div>

<h4>What's included:</h4>
    <ul>
        <li><i class="fa fa-check"></i><h6>Enjoy Assistance from a Dedicated VA for 4 Hours/Day and 5 Days/Week </h6> </li>
        <li><i class="fa fa-check"></i><h6>Get a Point Of Contact for all your work </h6></li>
        <li><i class="fa fa-check"></i><h6>Start, Stop, Change your Plan Any Time  </h6></li>
        <li><i class="fa fa-check"></i><h6>Buy Additional Hours If Needed </h6></li>
        <li><i class="fa fa-check"></i><h6>Furnished With Multi-Layered Quality Control System  </h6></li>
    </ul>

<Link to = "/contact-us">try popular</Link>

</div>

<div className="price_box">

<h1>pro</h1>

<div className="prices_part">
<span  className = "dollar"><i class="fa fa-usd"></i></span>
<span  className = "price">1600</span>
<span  className = "month">/month</span>
<h4>160 hours</h4>
</div>

<h4>What's included:</h4>
    <ul>
        <li><i class="fa fa-check"></i><h6>Enjoy Assistance from a Dedicated VA for 8 Hours/Day and 5 Days/Week </h6> </li>
        <li><i class="fa fa-check"></i><h6>Select your desired working hours </h6></li>
        <li><i class="fa fa-check"></i><h6>Get a Point Of Contact for all your work  </h6></li>
        <li><i class="fa fa-check"></i><h6>Start, Stop, Change your Plan Any Time </h6></li>
        <li><i class="fa fa-check"></i><h6>Furnished With Multi-Layered Quality Control System </h6></li>
    </ul>

<Link to = "/contact-us">try pro</Link>

</div>

    </div>

 </div>

</section>
*/
}

<PostingRole/>
<Assistant/>
<Booking/>
<Testimonials/>
<Faqs/>

<GetInTouch/>



 </Layout>



    
)




}



export default Virtual_assistants